<template>
  <div class="pt-15">
    <a
      v-for="item in items"
      :key="item.path"
      :text="item.name"
      class="mb-8 grey--text body-1 d-block text-none"
      @click="navigateTo(item.name)"
      v-html="`&rsaquo; ${unslugifyName(item.path)}`"
    />
  </div>
</template>

<script>
  import { unslugify } from 'unslugify'

  export default {
    name: 'SectionFooterLinksSecond',

    data: () => ({
      features: [
        'Referencje',
        'Kontakt',
        'RODO',
        'Polityka Cookies',
      ],
    }),
    computed: {
      items () {
        const routes = this.$router.options.routes[0].children.filter(route => route.meta.lang === this.$route.meta.lang).filter(route => route.name !== 'FourOhFour')
        const routeItems = []
        routes.slice(5, 10).forEach(route => {
          routeItems.push({
            name: route.name,
            path: route.path,
          })
        })
        return routeItems
      },
    },
    methods: {
      unslugifyName (name) {
        return unslugify(name)
      },
      navigateTo (name) {
        this.$router.push({ name })
      },
    },
  }
</script>
